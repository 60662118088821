export const topButton = ():void => {
	const topButton = document.querySelector<HTMLElement>(".js-top-button");

	if (topButton === null)	return;

	topButton.addEventListener("click", () => window.scrollTo({ top: 0, left: 0, behavior: "smooth" }));

	let lastPosition = 0;
	window.addEventListener("scroll", () => {
		const currentPosition = window.pageYOffset ;
		topButton.classList.toggle("fadeIn", currentPosition < lastPosition);
		lastPosition = currentPosition;
	});
};
