/* eslint-disable @stylistic/comma-dangle */

import { siteInfo }	from "@geotoura/shared/siteInfo_en";

import { Messages } from "@geotoura/common/privacy/locale";

export const messages:Messages = {
	title:	"Cookie settings",
	intro: {
		pre:	"In addition to the cookies that are technically necessary, we use cookies to analyse the use of our website. You can object to the use of cookies here or via our ",
		link: {
			href:	siteInfo.page.privacy,
			text:	"privacy page"
		},
		post:	"at any time."
	},
	essential:	"Technically necessary",
	nonessential: {
		pre:	"Statistics and marketing (",
		link: {
			href:	siteInfo.page.privacy,
			text:	"further information"
		},
		post:	")",
	},
	saveSettings:	"Save settings",
	acceptAll:		"Accept all",
	imprint: {
		pre:	"",
		link: {
			href:	siteInfo.page.impressum,
			text:	"Legal notice"
		},
		post:	"",
	}
};
