/* eslint-disable @stylistic/comma-dangle */

import { siteInfo }	from "@geotoura/shared/siteInfo_de";

import { Messages } from "@geotoura/common/privacy/locale";

export const messages:Messages = {
	title:	"Cookie-Einstellungen",
	intro: {
		pre:	"Neben den technisch notwendigen Cookies verwenden wir Cookies, um die Nutzung unserer Website zu analysieren. Sie können der Nutzung hier oder über unsere ",
		link: {
			href:	siteInfo.page.privacy,
			text:	"Datenschutz-Seite"
		},
		post:	"jederzeit widersprechen."
	},
	essential:	"Technisch notwendig",
	nonessential: {
		pre:	"Statistik und Marketing (",
		link: {
			href:	siteInfo.page.privacy,
			text:	"weitere Informationen"
		},
		post:	")",
	},
	saveSettings:	"Einstellungen speichern",
	acceptAll:		"Alle akzeptieren",
	imprint: {
		pre:	"",
		link: {
			href:	siteInfo.page.impressum,
			text:	"Impressum"
		},
		post:	"",
	}
};
