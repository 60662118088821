/* eslint-disable @stylistic/comma-dangle */

import { siteInfo }	from "@geotoura/shared/siteInfo_it";

import { Messages } from "@geotoura/common/privacy/locale";

export const messages:Messages = {
	title:	"Le impostazioni dei Cookie",
	intro:	{
		pre:	"Oltre ai cookie che sono tecnicamente necessari, utilizziamo i cookie per analizzare l’uso del nostro sito web. Potete ritirare il consenso al loro utilizzo qui o tramite la nostra ",
		link:	{
			href:	siteInfo.page.privacy,
			text:	"pagina sulla protezione dei dati"
		},
		post:	"in qualsiasi momento."
	},
	essential:	"Cookie tecnici",
	nonessential:	{
		pre:	"Statistica e Marketing (",
		link:	{
			href:	siteInfo.page.privacy,
			text:	"Ulteriori informazioni"
		},
		post:	")",
	},
	saveSettings:	"Salvare le impostazioni",
	acceptAll:		"Accettare tutto",
	imprint:	{
		pre:	"",
		link:	{
			href:	siteInfo.page.impressum,
			text:	"Note legali"
		},
		post:	"",
	}
};
